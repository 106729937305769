import React from 'react';

function ChooseRegClass() {
    return (
        <div>
            <h2>Choose an Existing Reg Class</h2>
        </div>
    );
}

export default ChooseRegClass;
